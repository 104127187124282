.a-icon {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: $black;

  body.is-high-contrast-active & {
    color: $white;

    &:hover {
      color: rgba(255, 152, 0, 1)
    }
  }

  &:before {
    content: "";
  }

  &--arrow {
    width: 10px;
    height: 10px;

    &:before {
      position: absolute;
      display: inline-block;
      inset: 0;
      background-color: currentColor;
      mask-image: url("../../../../../assets/private/img/triangle-arrow.svg");
      transition: color $transition-base;
    }
  }

  &--contrast {
    width: 18px;
    height: 18px;
    color: currentColor;

    &:before {
      position: absolute;
      display: inline-block;
      inset: 0;
      background-color: currentColor;
      mask-image: url("../../../../../assets/private/img/contrast.svg");
      mask-size: cover;
      transition: color $transition-base;
    }
  }

  &--link,
  &--circle,
  &--stairs,
  &--bitten-rectangle,
  &--plus,
  &--equals,
  &--message,
  &--fuji,
  &--chessboard,
  &--square-with-hole,
  &--fast-forward-up,
  &--duplicate-with-hole,
  &--triangles-to-middle {
    width: 1em;
    height: 1em;
    font-size: 100px;
    line-height: 0;
    transition: color $transition-base;

    &:before {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      right: 0;
      width: 200%;
      height: 200%;
      border: solid 0.26em currentColor;
      border-radius: 50%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0.38em;
      height: 0.38em;
      background-color: currentColor;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: 0 100%;
    }
  }

  &--link {
    &:before {
      border-width: 0.43em;
    }

    &:after {
      width: 0;
      height: 0;
      border: solid 0.35em transparent;
      border-top-color: currentColor;
      background: transparent;
      transform: translate(-50%, 50%) rotate(45deg);
    }
  }

  &--circle::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-circle.svg");
  }

  &--stairs::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-stairs.svg");
  }

  &--bitten-rectangle::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-bitten-rectangle.svg");
  }

  &--plus::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-plus.svg");
  }

  &--equals::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-equals.svg");
  }

  &--message::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-message.svg");
  }

  &--fuji::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-fuji.svg");
  }

  &--chessboard::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-chessboard.svg");
  }

  &--square-with-hole::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-square-with-hole.svg");
  }

  &--fast-forward-up::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-fast-forward-up.svg");
  }

  &--duplicate-with-hole::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-duplicate-with-hole.svg");
  }

  &--triangles-to-middle::after {
    mask-image: url("../../../../../assets/private/img/shapes/shape-triangles-to-middle.svg");
  }
}
