.m-heading {
  &__container {
  }

  &__date {

  }

  &__title {
    margin-bottom: 0;
    text-transform: uppercase;

    @include media-breakpoint-up($header-breakpoint) {
      margin-bottom: 2.5rem;
    }
  }

  &__breadcrumbs {
  }

  .breadcrumbs {
    &__list {
      list-style: none;
      display: inline-flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
    }

    &__item {
      display: inline-block;

      &:last-child {
        .breadcrumbs__link:after {
          display: none;
        }
      }
    }

    &__link {
      display: flex;
      font-family: $font-family-headings;
      font-size: $font-size-sm;
      text-transform: uppercase;
      color: $black;
      transition: color $transition-base;

      body.is-high-contrast-active & {
        color: $white;
      }

      body.font-scale-mode--1 & {
        font-size: $font-size-sm * $font-scale-1-multiplier;
      }

      body.font-scale-mode--2 & {
        font-size: $font-size-sm * $font-scale-2-multiplier;
      }

      &:hover {
        color: $primary
      }

      &:after {
        content: '/';
        display: inline-block;
        margin: 0 .5rem;
        color: $black;

        body.is-high-contrast-active & {
          color: $white;
        }
      }
    }
  }
}