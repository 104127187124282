.m-banner-image {
  &__container {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  &__inner {
    position: relative;
    display: block;
    color: $white;
    transition: color $transition-base;
    cursor: pointer;

    body.is-high-contrast-active & {
      color: $white;
    }

    &:hover {
      color: $primary;
    }
  }

  &__bg {
    z-index: 1;
    position: relative;
  }

  &__image {
  }

  &__icon {
    position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
    font-size: 30px;
    color: currentColor;
    transition: none;
  }

  &__content {
    z-index: 10;
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
    max-width: calc(100% - 30px - 1rem);
  }

  &__title {
    margin: 0;
    text-shadow: 0 0 1rem rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
    text-align: right;

    @include media-breakpoint-down(xxs) {
      font-size: $font-size-lg;
    }

    @include media-breakpoint-up(lg) {
      letter-spacing: 2px;
    }

    body.is-high-contrast-active & {
      text-shadow: none;
      background-color: rgba($black, 0.9);
    }

    body.font-scale-mode--1 & {
      @include media-breakpoint-down(xxs) {
        font-size: $font-size-lg * $font-scale-1-multiplier;
      }
    }

    body.font-scale-mode--2 & {
      @include media-breakpoint-down(xxs) {
        font-size: $font-size-lg * $font-scale-2-multiplier;
      }
    }
  }
}