.a-language-switcher {
  display: inline-flex;
  list-style: none;
  height: 40px;
  padding: 0;
  margin: 0;
  &__item {
    height: 100%;

    &.is-active {
      .a-language-switcher__link {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 1rem;
    font-family: $font-family-headings;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    color: $black;
    text-transform: uppercase;

    &:after {
      content: "";
      opacity: 0;
      width: 2ch;
      height: 1px;
      margin-top: -5px;
      background-color: currentColor;
    }

    &:hover {
      color: #404040;
    }
  }
}
