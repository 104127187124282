$rem-value: 8px;

$header-breakpoint: xl;
$header-height-mobile: 50px;
$header-height: 130px;

$font-scale-1-multiplier: 1.25;
$font-scale-2-multiplier: 1.5;

@import "grid";
@import "transitions";
@import "colors";
@import "typography";