.l-header {
  display: none;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;

  @include media-admin-bar() {
    top: 32px;
  }

  @include media-breakpoint-up($header-breakpoint) {
    display: flex;
  }

  &__top {
    z-index: 100;
    position: relative;
    flex: 0 0 auto;
    height: $header-height-mobile;
    background-color: $primary;

    body.is-high-contrast-active & {
      background-color: $black;
      border-bottom: solid 1px $white;
    }

    .top {
      &__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 100%;
      }

      &__high-contrast,
      &__font-scale,
      &__language-switcher {
        margin-right: 2rem;
      }
    }
  }

  &__main {
    z-index: 100;
    position: relative;
    flex: 1 1 auto;
    background-color: $white;

    body.is-high-contrast-active & {
      background-color: $black;
    }
  }

  .main {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      padding-right: 0;
    }

    &__logo {
      margin-bottom: 2rem;
    }

    &__menu {
      margin-left: 4rem;
      margin-bottom: 1rem;
    }

    .menu {
      &__list {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        padding: 0;
        margin: 0 0 0 -1rem;
      }

      &__item {
        display: flex;
        margin-right: 1rem;

        @include media-breakpoint-up(xxl) {
          margin-right: 2.75rem;
        }
      }

      .item {

        &__link {
          position: relative;
          display: inline-flex;
          align-items: center;
          padding: 1rem;
          line-height: 1;
          font-size: $font-size-sm;
          font-family: $font-family-headings;
          text-transform: uppercase;
          color: $black;

          body.is-high-contrast-active & {
            color: $white;
          }

          body.font-scale-mode--1 & {
            font-size: $font-size-sm * $font-scale-1-multiplier;
          }

          body.font-scale-mode--2 & {
            font-size: $font-size-sm * $font-scale-2-multiplier;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 1rem;
            width: 0;
            height: 1px;
            background-color: currentColor;
            transition: width $transition-base, background-color $transition-base;
          }

          &-text {
            transition: color $transition-base;
          }


          &:hover {
            color: $primary;
          }

          &:hover,
          &--current {
            &:after {
              width: calc(100% - 1rem);
              background-color: $primary;

              body.is-high-contrast-active & {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }

  &__megamenu {
    z-index: 90;
    position: absolute;
    overflow: hidden;
    top: 100%;
    right: 0;
    left: 0;
    height: 99999px;
    max-height: 0;
    border-bottom: solid 1px $black;
    transition: max-height $transition-base;

    body.is-high-contrast-active & {
      border-bottom-color: $white;
    }

    &.is-open {

    }
  }

  .megamenu {
    &__inner {
      background-color: $white;

      body.is-high-contrast-active & {
        background-color: $black;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__menus {
      padding: 0;
      margin: 2rem 0 4rem 25%;

      @include media-breakpoint-up(xxl) {
        margin: 4rem 0 6rem 25%;
      }
    }

    &__menu {
      display: none;

      &.is-active {
        display: block;
      }
    }

    .menu {
      &__inner {
        list-style: none;
        column-count: 2;
        column-gap: 15%;
        padding: 0;
        margin: 0 0 0 -1rem;
      }

      &__item {
        break-inside: avoid;

      }

      &__link {
        position: relative;
        display: block;
        padding: 1.5rem 1rem;
        margin-bottom: 1rem;
        color: $black;
        font-family: $font-family-headings;
        transition: color $transition-base;

        body.is-high-contrast-active & {
          color: $white;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 1rem;
          width: calc(100% - 3.5rem);
          height: 1px;
          background-color: currentColor;
          transition: width $transition-base, background-color $transition-base;
        }

        &:hover {
          color: $primary;
        }

        &:hover,
        &--current {
          &:after {
            width: calc(100% - 1rem);
            background-color: $primary;

            body.is-high-contrast-active & {
              background-color: $white;
            }
          }
        }
      }
    }

    &__secondary-nav {
      margin-bottom: 4rem;
      margin-left: 25%;
      padding: 0;

      @include media-breakpoint-up(xxl) {
        margin-bottom: 6rem;
      }
    }

    &__links {
      justify-content: flex-end;
      margin-bottom: 1.5rem;
      margin-left: 25%;

      @include media-breakpoint-up(xxl) {
        margin-bottom: 2.5rem;
      }

      .m-links__item {
        margin-left: 1rem;

        @include media-breakpoint-up(xxl) {
          margin-left: 2rem;
        }

        &:last-child {
          margin-right: -1.5rem;
        }
      }
    }
  }

  &__backdrop {
    z-index: 80;
    pointer-events: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: calc(100vh - #{$header-height});
    opacity: 0;
    background-color: rgba($black, 0.5);
    transition: opacity $transition-base;

    body.is-high-contrast-active & {
      background-color: rgba($white, 0.5);
    }

    &.is-visible {
      pointer-events: all;
      opacity: 1;
    }
  }

}