.a-logo {
  display: flex;
  align-items: center;

  &__img {
    display: block;
    max-width: 202px;
    height: auto;
    svg {
    max-width: 202px;
    height: auto;

      body.is-high-contrast-active & {
        filter: invert(100%);
      }
    }
  }
}

.font-scale-mode--2 .l-header__main .a-logo__img {
  display: none;
}
