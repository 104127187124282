.a-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    display: block;
    box-sizing: content-box;
    width: 2ch;
    padding: 1rem;
    border-bottom: solid 1px $black;
    text-align: center;
    transition: color $transition-base, background-color $transition-base, border-bottom-color $transition-base;

    &:hover {
      border-bottom-color: $primary;
      color: $black;
    }

    &.is-active {
      border-bottom-color: $primary;
      background-color: $primary;
      color: $white;
    }
  }
}