.o-departments-map {
  &__title {
    margin-bottom: 1.25rem;
  }

  &__map {
    height: 60vh;
    max-height: 650px;

    @include media-breakpoint-up(lg) {
      height: 650px;
    }

    .ol-zoom {
      pointer-events: auto;
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        right: 2.5rem;
        bottom: 2.5rem;
      }

      &-in,
      &-out {
        position: relative;
        width: 30px;
        height: 30px;
        padding: 0;
        font-size: 0;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 30px;
          height: 5px;
          background-color: $black;
          transform: translate(-50%, -50%);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      &-in {

      }

      &-out {
        &:after {
          display: none;
        }
      }
    }

    .ol-rotate,
    .ol-attribution {
      display: none;
    }
  }

  &__marker {
    font-size: 30px;
    cursor: pointer;
    transition: color $transition-base;

    body.is-high-contrast-active & {
      color: $black;
    }

    &:hover {
      color: $primary;

      body.is-high-contrast-active & {
        color: $black;
      }
    }
  }

  &__popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
    padding: 2rem;
    border: solid 1px $black;
    background-color: $white;
    transition: opacity $transition-base;

    body.is-high-contrast-active & {
      border-color: $white;
      background-color: $black;
    }

    &-wrapper {
      z-index: 100;

      @include media-breakpoint-up(lg) {
        width: 33%;

      }
    }

    span {
      margin-bottom: 2rem;
      font-size: 30px;
      color: $primary;

      body.is-high-contrast-active & {
        color: $white;
      }
    }

    a {
      position: relative;
      display: block;
      min-width: 100%;
      padding: .5rem 4rem .5rem 0;
      border-bottom: solid 1px currentColor;
      margin-bottom: 2rem;
      color: $black;
      transition: color $transition-base;

      body.is-high-contrast-active & {
        color: $white;
      }

      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 10px;
        height: 10px;
        top: 50%;
        right: 0;
        background-color: currentColor;
        mask-image: url('../../../../../assets/private/img/triangle-arrow.svg');
        transform: translateY(-50%) rotate(-90deg);
      }

      &:hover {
        color: $primary;
      }
    }

    p {
      margin: 0;
      white-space: pre;
    }

    &.is-hidden {
      pointer-events: none;
      opacity: 0;
    }
  }
}