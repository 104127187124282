.m-secondary-nav {
  padding: 0 2rem;

  &__list {
    list-style: none;
    height: 100%;
    padding: 0;
    margin: 0 -2rem;

    @include media-breakpoint-up($header-breakpoint) {
      display: flex;
      margin: 0 -1rem;
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(xl) {
      margin-right: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 4rem;
    }
  }

  .item {
    &__link {
      position: relative;
      display: inline-block;
      padding: 1.5rem 2rem;
      line-height: 1;
      color: $black;
      text-transform: uppercase;
      font-family: $font-family-headings;
      transition: color $transition-base;

      @include media-breakpoint-up($header-breakpoint) {
        padding: 1.5rem 1rem;
      }

      body.is-high-contrast-active & {
        color: $white;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: .75rem;
        left: 2rem;
        width: 0;
        height: 1px;
        background-color: currentColor;
        transition: width $transition-base, background-color $transition-base;

        @include media-breakpoint-up(xl) {
          left: 1rem
        }
      }

      &:hover {
        color: $primary;
      }

      &:hover,
      &--current {
        &:after {
          width: calc(100% - 2rem);
          background-color: $primary;

          @include media-breakpoint-up(xl) {
            width: calc(100% - 1rem);
          }

          body.is-high-contrast-active & {
            background-color: $white;
          }
        }
      }
    }
  }
}