.a-high-contrast {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  color: $black;
  &:hover {
    color: #404040;
  }
  &__icon {
    pointer-events: none;
    color: currentColor;
  }
}
