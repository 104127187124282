.m-search-bar {
  display: inline-flex;

  &__input {
    overflow: hidden;
    width: 200px;
    max-width: 0;
    transition: max-width $transition-base;

    &-inner {
      width: 200px;
      height: 40px;
      padding: 0;
      border: 0;
      border-bottom: solid 1px currentColor;
      background-color: transparent;
      color: inherit;

      &:focus {
        outline: 0;
      }
    }
  }

  &__submit {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    background-color: currentColor;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: url('../../../../../assets/private/img/magnifier.svg');
    color: inherit;
  }

  &.is-open {
    .m-search-bar {
      &__input {
        max-width: 200px;
      }
    }
  }

  body.is-high-contrast-active & {
    color: $white
  }
}