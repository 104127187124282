.m-picture {
  &__container {
  }

  &__description {
    margin-top: 1rem;
    font-size: $font-size-sm;

    body.font-scale-mode--1 & {
      font-size: $font-size-sm * $font-scale-1-multiplier;
    }

    body.font-scale-mode--2 & {
      font-size: $font-size-sm * $font-scale-2-multiplier;
    }
  }
}