.o-main-nav-mobile {
  padding: 0 2rem;

  &__list {
    list-style: none;
    height: 100%;
    padding: 0;
    margin: 0 -2rem;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    &__link {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 1.5rem 2rem;
      font-size: $font-size-sm;
      line-height: 1;
      font-family: $font-family-headings;
      color: $black;
      text-transform: uppercase;

      body.font-scale-mode--1 & {
        font-size: $font-size-sm * $font-scale-1-multiplier;
      }

      body.font-scale-mode--2 & {
        font-size: $font-size-sm * $font-scale-2-multiplier;
      }

      body.is-high-contrast-active & {
        color: $white;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0.75rem;
        left: 2rem;
        width: 0;
        height: 1px;
        background-color: currentColor;
        transition: width $transition-base, background-color $transition-base;
      }

      &-text {
        transition: color $transition-base;
      }

      &-icon {
        margin-left: 0.5rem;
        color: currentColor;
        transition: transform $transition-base;
      }

      &:hover {
        color: $primary;
      }

      &:hover,
      &--current {
        &:after {
          width: calc(100% - 3rem);
          background-color: $primary;

          body.is-high-contrast-active & {
            background-color: $white;
          }
        }
      }
    }

    &__submenu {
      position: relative;
      overflow: hidden;
      flex-basis: 100%;
      width: 100%;
      // height: 99999px;
      // max-height: 0;
      margin-left: 2rem;
      // transition: max-height $transition-base;
    }

    .submenu {
      &__inner {
        list-style: none;
        // position: absolute;
        // top: 0;
        // right: 0;
        // left: 0;
        padding: 0 0 2rem 0;
      }

      &__item {
        margin-bottom: 1rem;
      }

      &__link {
        position: relative;
        display: block;
        padding: 1.5rem 2rem;
        line-height: 1;
        font-size: $font-size-sm;
        font-family: $font-family-headings;
        color: $black;
        transition: color $transition-base;

        body.is-high-contrast-active & {
          color: $white;
        }

        body.font-scale-mode--1 & {
          font-size: $font-size-sm * $font-scale-1-multiplier;
        }

        body.font-scale-mode--2 & {
          font-size: $font-size-sm * $font-scale-2-multiplier;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 2rem;
          width: calc(100% - 6.5rem);
          height: 1px;
          background-color: currentColor;
          transition: width $transition-base, background-color $transition-base;
        }

        &:hover {
          color: $primary;
        }

        &:hover,
        &--current {
          &:after {
            width: calc(100% - 4rem);
            background-color: $primary;

            body.is-high-contrast-active & {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  &__item.is-open {
    .item {
      &__link {
        &-icon {
          transform: translateY(-2px) rotate(-90deg);
        }
      }
    }
  }
}

.menu__link-subsubmenu {
  position: relative;
  display: block;
  padding: 1.5rem 2rem;
  line-height: 1;
  font-size: $font-size-sm;
  font-family: $font-family-headings;
  color: $black;
  transition: color $transition-base;

  body.is-high-contrast-active & {
    color: $white;
  }

  body.font-scale-mode--1 & {
    font-size: $font-size-sm * $font-scale-1-multiplier;
  }

  body.font-scale-mode--2 & {
    font-size: $font-size-sm * $font-scale-2-multiplier;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 2rem;
    width: calc(100% - 6.5rem);
    height: 1px;
    background-color: currentColor;
    transition: width $transition-base, background-color $transition-base;
  }

  &:hover {
    color: $primary;
  }

  &:hover,
  &--current {
    &:after {
      width: calc(100% - 4rem);
      background-color: $primary;

      body.is-high-contrast-active & {
        background-color: $white;
      }
    }
  }
}

.item__link-icon-submenu {
  margin-left: 0.5rem;
  color: currentColor;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.submenu__item--gran.is-open .item__link-icon-submenu {
  -webkit-transform: translateY(-2px) rotate(-90deg);
  transform: translateY(-2px) rotate(-90deg);
}
.item__submenu.item__subsubmenu.submenu.js-main-nav-mobile__submenu-container
  .menu__inner-subsubmenu {
  padding-left: 2rem;
}
.js-main-nav-mobile__submenu-container {
  display: none;
}
.menuIsOpen {
  display: block;
}