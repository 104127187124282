.m-video {
  &__container {
  }

  &__inner {
    position: relative;
    cursor: pointer;
    background-color: $grey-100;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-top: 56.25%;
    }

    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      border: solid 20px $primary;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation-name: spin;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
  }


  &__image {
    z-index: 5;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: opacity $transition-base, transform $transition-base;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      width: 143px;
      height: 100px;
      background-image: url('../../../../../assets/private/img/youtube_logo.svg');
      background-size: cover;
      transform: translate(-50%, -50%);
    }
  }

  iframe {
    z-index: 2;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  &__description {
    margin-top: 1rem;
    font-size: $font-size-sm;

    body.font-scale-mode--1 & {
      font-size: $font-size-sm * $font-scale-1-multiplier;
    }

    body.font-scale-mode--2 & {
      font-size: $font-size-sm * $font-scale-2-multiplier;
    }
  }

  &.is-active {
    .m-video {
      &__image {
        pointer-events: none;
        opacity: 0;
        transform: scale(0.9);
      }
    }
  }
}