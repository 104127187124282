.o-footer {
  background-color: $primary;
  margin-top: 4rem;

  @include media-breakpoint-up(md) {
    margin-top: 6rem;
    background-color: $white;
  }

  body.is-high-contrast-active & {
    background-color: $black;
  }

  &__container {
  }

  &__inner {
    position: relative;
    overflow: hidden;
    padding: 4rem 0;

    @include media-breakpoint-up(md) {
      padding: 4rem 0 0;
      border-top: solid 1px $black;
      margin-bottom: 4rem;

      body.is-high-contrast-active & {
        border-top-color: $white;
      }
    }

    @include media-breakpoint-up(xl) {
      padding-top: 5rem;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: 6rem;
    }
  }

  &__top {
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
      //margin-bottom: 8rem;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 10rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-bottom: 12rem;
    }
  }

  &__logo {
    @include media-breakpoint-up(md) {
      width: 32%;
      span,
      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &__bottom {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  &__contact {
    margin-bottom: 4rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    &-info {
      a {
        color: $black;
        transition: color $transition-base;

        body.is-high-contrast-active & {
          color: $white;
        }

        &:hover {
          color: $white;

          @include media-breakpoint-up(lg) {
            color: $primary;
          }
        }
      }
    }
  }
  &__menu-title {
    font-weight: 700;
    margin-bottom: 3rem;
  }
  &__menus {
  }

  &__menu {
    &--desktop {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &--mobile {
      @include media-breakpoint-up(sm) {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        padding-bottom: 3rem;
      }
    }
    &--secondary {
      position: relative;
      padding: 4.5rem 0;
      &:after {
        content: "";
      }
    }
  }

  .menu {
    &__list {
      list-style: none;
      padding: 0;
      margin: 0 -1rem;
    }

    &__item {
      break-inside: avoid;
    }

    &__link {
      position: relative;
      display: inline-block;
      padding: 0.5rem 1rem;
      font-weight: $font-weight-medium;
      color: $black;
      transition: color $transition-base;

      body.is-high-contrast-active & {
        color: $white;
      }

      &:after {
        content: "";
        position: absolute;
        left: 1rem;
        bottom: 0;
        width: 0;
        height: 1px;
        background-color: currentColor;
        transition: width $transition-base, background-color $transition-base;
      }

      &:hover {
        color: $white;

        @include media-breakpoint-up(md) {
          color: $primary;
        }

        &:after {
          width: calc(100% - 1rem);
          background-color: $white;

          @include media-breakpoint-up(md) {
            background-color: $primary;

            body.is-high-contrast-active & {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  &__menu--main {
    margin-bottom: $paragraph-margin-bottom-mobile;

    @include media-breakpoint-up($paragraph-margin-bottom-breakpoint) {
      margin-bottom: $paragraph-margin-bottom;
    }
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid #000;
      margin-bottom: 0;
    }
    ul {
      li {
        &:last-child {
          @include media-breakpoint-down(sm) {
            padding-top: 3rem;
          }
        }
      }
    }
    .menu {
      &__list {
        @include media-breakpoint-up(sm) {
          column-count: 1;
          column-gap: $grid-gutter-width;
        }

        @include media-breakpoint-up($grid-breakpoint-change) {
          column-gap: $grid-gutter-width-desktop;
        }
      }
    }
  }

  &__links {
    margin-top: 4rem;
    line-height: 0;

    @include media-breakpoint-up(xl) {
      margin-top: 9rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-top: 10rem;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 4.5rem;
      margin-top: 0;
      border-top: 1px solid #000;
    }

    &-inner {
      margin: 0;

      @include media-breakpoint-up(sm) {
        justify-content: flex-end;
      }
    }

    .m-links {
      @include media-breakpoint-down(sm) {
        width: 80%;
        display: flex;
        border: none;
        margin: 0 auto;
        justify-content: center;
      }
      &__item {
        @include media-breakpoint-up(md) {
          margin-left: 1rem;
        }

        @include media-breakpoint-up(xl) {
          margin-left: 2.25rem;
        }
        @include media-breakpoint-up(xl) {
          margin-left: 4.5rem;
        }

        @include media-breakpoint-down(sm) {
          display: flex;
          flex: 0 0 25%;
          justify-content: center;
          margin-bottom: 3rem;
        }
      }

      &__link {
        &:hover {
          color: $white;

          @include media-breakpoint-up(lg) {
            color: $primary;
          }
        }
      }
    }
  }

  &__ornament {
    pointer-events: none;
    z-index: 1;
    position: absolute;
    display: none;
    left: 0;
    bottom: 0;
    width: calc(50% - #{$grid-gutter-width});
    transform: translate(-50%, 50%);

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up($grid-breakpoint-change) {
      width: calc(50% - #{$grid-gutter-width-desktop});
    }

    &:after {
      content: "";
      display: block;
      padding-top: calc(100% - 120px);
      border: solid 60px $primary;
      border-radius: 50%;
      box-sizing: content-box;

      body.is-high-contrast-active & {
        border-color: $white;
      }

      @include media-breakpoint-up(lg) {
        padding-top: calc(100% - 180px);
        border-width: 90px;
      }

      @include media-breakpoint-up(xl) {
        padding-top: calc(100% - 240px);
        border-width: 120px;
      }

      @include media-breakpoint-up(xxl) {
        padding-top: calc(100% - 280px);
        border-width: 140px;
      }
    }
  }

  &__copyright {
    display: none;

    &-text {
    }
  }
  &__fundslogo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      background: white;
      justify-content: center;
      padding: 5rem 0;
      img {
        width: 40%;
        margin: 1rem 0;
      }
    }
  }
}
#scrollToTopBtn {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 100;
  display: none;
  width: 5rem;
  height: 5rem;
  background-color: rgba($orange, 0.6);
  border-radius: 100%;
  border: 2px solid #fff;
}

#scrollToTopBtn:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.o-footer-circle {
  position: absolute;
  border-left: 120px solid white;
  border-top: 120px solid white;
  border-top-left-radius: 100%;
  width: 300px;
  top: 0;
  right: -20px;
  bottom: 0;
  z-index: -1;
}
body.is-high-contrast-active .o-footer-circle {
  border-left: 120px solid #404040;
  border-top: 120px solid #404040;
}
