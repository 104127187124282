.a-contact-info {
  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid #000;
  }
  p {
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: block;
      @include media-breakpoint-down(sm) {
        font-weight: 700;
        padding-bottom: 2rem;
      }
    }
  }

  &__additional {
    @include media-breakpoint-down(sm) {
      p {
        span {
          font-weight: 700;
          padding-top: 2.5rem;
          padding-bottom: 4.5rem;
        }
      }
    }
  }
}
