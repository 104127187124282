.l-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $header-height-mobile;

  @include media-breakpoint-up($header-breakpoint) {
    padding-top: $header-height;
  }

  @include media-admin-bar() {
    padding-top: calc(#{$header-height-mobile} + 32px);

    @include media-breakpoint-up($header-breakpoint) {
      padding-top: calc(#{$header-height} + 32px);
    }
  }

  &__main {
    flex: 1 1 auto;
  }
}