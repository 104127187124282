.m-alphabetical-list {
  &__container {
  }

  &__letters {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      margin-bottom: 1.25em;
    }
  }

  &__title {
    margin: 0;
  }

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    &-inner {
      color: $black;
      transition: color $transition-base;

      body.is-high-contrast-active & {
        color: $white
      }

      &:hover {
        color: $primary;
      }
    }
  }
}