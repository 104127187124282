$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;

.a-hamburger {
  padding: 0 15px;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
  line-height: 0;

  &__box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
  }

  &__inner {
    display: block;
    top: $hamburger-layer-height / 2;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $black;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;

      body.is-high-contrast-active & {
        background-color: $white;
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }

    &::after {
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
    }
  }

  &.is-active {
    .a-hamburger {
      &__inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        transform: translate3d(0, $y-offset, 0) rotate(45deg);

        &::before {
          transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);

        }
      }
    }
  }
}