.m-form {
  &__container {

  }

  &__inner {
    width: 100%;
    max-width: 670px;
  }

  &__title {

  }

  .wpcf7 {
    &-form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-submit {
      border: solid 1px $black;
      background-color: transparent;
      padding: 1.5rem;
      width: 100%;
      max-width: 210px;
      transition: border $transition-base, background-color $transition-base, color $transition-base;

      body.is-high-contrast-active & {
        border-color: $white;
        color: $white;
      }

      &:hover {
        border-color: $primary;
        background-color: $primary;
        color: $white;

        body.is-high-contrast-active & {
          background-color: transparent;
        }
      }
    }

    &-form-control-wrap {
      display: block;
      width: 100%;
      margin-bottom: 2rem;
      line-height: 0;

      input[type="text"],
      input[type="email"],
      textarea {
        transition: border $transition-base;
        line-height: 1.25em;

        &::placeholder {
          color: rgba($black, 0.5)
        }

        &:focus {
          outline: none;
          border-color: $primary;
        }

        &.wpcf7-validates-as-required {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24"><text transform="translate(0 19)" fill="rgb(0 0 0)" font-size="18" font-family="OpenSans, Open Sans"><tspan x="0" y="0">*</tspan></text></svg>');
          background-repeat: no-repeat;
          background-position: 100% 1rem;

          body.is-high-contrast-active & {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24"><text transform="translate(0 19)" fill="rgb(255 255 255)" font-size="18" font-family="OpenSans, Open Sans"><tspan x="0" y="0">*</tspan></text></svg>');
          }
        }

        &.wpcf7-not-valid {
          border-color: $error;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24"><text transform="translate(0 19)" fill="rgb(182 25 10)" font-size="18" font-family="OpenSans, Open Sans"><tspan x="0" y="0">*</tspan></text></svg>');
        }

        body.is-high-contrast-active & {
          background-color: $black;
          color: $white;

          &::placeholder {
            color: $white;
          }
        }
      }

      input[type="text"],
      input[type="email"] {
        width: 100%;
        padding: 1rem 0;
        border: none;
        border-bottom: solid 1px $black;

        body.is-high-contrast-active & {
          border-bottom-color: $white;
        }
      }

      textarea {
        width: 100%;
        border: solid 1px $black;
        padding: 1rem;

        body.is-high-contrast-active & {
          border-color: $white;
        }
      }
    }

    label {
      width: 100%;
      margin: 0;

      & > span:first-child {
        display: block;
        font-weight: $font-weight-bold;
        margin-bottom: 1rem;
      }
    }

    &-not-valid-tip {
      display: inline-block;
      box-sizing: content-box;
      height: 1.25em;
      padding: .5rem 1rem;
      margin: 0 0 0 2rem;
      font-size: $font-size-sm;
      line-height: 1.25em;
      background-color: $error;
      color: $white;

      @include media-breakpoint-up(sm) {
        margin-left: 4rem;
      }

      body.font-scale-mode--1 & {
        font-size: $font-size-sm * $font-scale-1-multiplier;
      }

      body.font-scale-mode--2 & {
        font-size: $font-size-sm * $font-scale-2-multiplier;
      }
    }
  }
}