.o-accordion {
  &__container {

  }

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    position: relative;
    margin-bottom: 3rem;

    .item {
      &__header {
        padding: 1rem 2rem 1rem 0;
        margin: 0;
        cursor: pointer;
      }

      &__main {
        overflow: hidden;
        max-height: 0;
        transition: max-height $transition-base;

        &-inner {
          padding: 2rem 0 4rem;

          @include media-breakpoint-up(xl) {
            padding: 4rem 0;
          }
        }
      }

      &__list {
        list-style: none;
        padding: 0;

        .list {
          &__item {
            display: block;
            position: relative;
            padding-bottom: 2rem;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              max-width: 96px;
              background-color: $black;
              transition: max-width $transition-base, background-color $transition-base;

              body.is-high-contrast-active & {
                background-color: $white;
              }
            }

            &-con {
              margin-bottom: 2.5rem;
            }

            &-subtitle {
              margin-bottom: 0;
              text-transform: uppercase;
              transition: color $transition-base;
            }

            &-content {
              p:last-child {
                margin-bottom: 0;
              }
            }

            &--link {
              color: $black;

              body.is-high-contrast-active & {
                color: $white;
              }

              &:hover {
                &:after {
                  width: 100%;
                  max-width: 100%;
                  background-color: $primary;
                }

                .list__item-subtitle {
                  color: $primary;

                  body.is-high-contrast-active & {
                    background-color: $white;
                  }
                }
              }
            }

          }
        }
      }

      &__toggler {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4rem;
        border-bottom: solid 2px $black;
        padding: 0;
        transition: border-bottom-color $transition-base;

        body.is-high-contrast-active & {
          border-bottom-color: $white;
        }

        &-icon {
          margin-bottom: 1.25rem;
          transition: transform $transition-base;
        }
      }
    }

    &.is-active {
      .item__toggler {
        border-bottom-color: $primary;

        body.is-high-contrast-active & {
          border-bottom-color: $white;
        }

        &-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .l-sidebar & {
    &__items {}
    &__item {
      .item {
        &__list {
          .list {
            &__item {
              &-con {
                @include media-breakpoint-up($header-breakpoint) {
                  max-width: 88.8888889%;
                }
              }
            }
          }
        }
      }
    }
  }
}