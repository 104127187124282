.l-search-page {
  &__header {
    padding: 6rem 0;
    background-color: $primary;
    margin-bottom: 6rem;

    .header {
      &__container {
      }

      &__title {
        text-align: center;
        margin: 0;
      }

      &__form {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 6rem 0;
      }

      &__subtitle {
        text-align: center;
        margin: 0;
      }

      &__input {
        width: 100%;
        height: 40px;
        max-width: 300px;
        padding: 0;
        border: 0;
        border-bottom: solid 1px currentColor;
        background-color: transparent;
        text-align: left;
        color: inherit;

        &:focus {
          outline: 0;
        }
      }

      &__submit {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-image: url('../../../../assets/private/img/magnifier.svg');
        color: inherit;
      }
    }
  }

  &__articles-list {
    .m-articles-list__items {
      @include media-breakpoint-up(lg) {
        max-width: 75%;
        margin: 0 auto;
      }
    }
  }

  body.is-high-contrast-active & {
    .l-search-page {
      &__header {
        background-color: $black;
        border-top: solid 1px currentColor;
        border-bottom: solid 1px currentColor;
      }
    }
  }
}