.l-section {
  $desktop: (
          0: 1.5rem,
          1: 7rem,
          2: 10rem,
          3: 13rem,
          4: 16rem,
          5: 19rem
  );


  $mobile: (
          0: 1rem,
          1: 3.5rem,
          2: 5rem,
          3: 6.5rem,
          4: 8rem,
          5: 9.5rem
  );

  &--mt {
    @for $i from 0 through 5 {
      &-#{$i} {
        margin-top: map-get($mobile, $i);

        @include media-breakpoint-up($header-breakpoint) {
          margin-top: map-get($desktop, $i);
        }
      }
    }
  }

  &--mb {
    @for $i from 0 through 5 {
      &-#{$i} {
        margin-bottom: map-get($mobile, $i);

        @include media-breakpoint-up($header-breakpoint) {
          margin-bottom: map-get($desktop, $i);
        }
      }
    }
  }
}