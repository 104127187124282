.m-articles-list {
  &__container {

  }

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin-bottom: 4rem;
    .item {
      &__inner {
        color: $black;

        body.is-high-contrast-active & {
          color: $white
        }

        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      &__header {
        flex: 0 0 auto;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
          width: 210px;
          margin: 0 #{$grid-gutter-width} 0 0;
        }

        @include media-breakpoint-up($grid-breakpoint-change) {
          margin: 0 #{$grid-gutter-width-desktop} 0 0;
        }
      }

      &__image {
      }

      &__content {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: .5rem;
        border-bottom: solid 1px $black;
        transition: border-bottom-color $transition-base;

        body.is-high-contrast-active & {
          border-bottom-color: $white;
        }
      }

      &__title {
        width: 100%;
        text-transform: uppercase;
        transition: color $transition-base;
      }

      &__text {
        width: 100%;
        margin-bottom: 3rem;
      }

      &__date {
        width: 100%;

        @include media-breakpoint-up(md) {
          margin-top: auto;
        }
      }

      &__icon {
        position: absolute;
        right: 0;
        bottom: 1.25rem;
        transform: rotate(-90deg);
        transition: transform $transition-base;
      }
    }

    &:hover {
      .item {
        &__title {
          color: $primary;

          body.is-high-contrast-active & {
            color: $white;
          }
        }

        &__content {
          border-bottom-color: $primary;
        }

        &__icon {
          transform: rotate(0);
          color: $primary;

          body.is-high-contrast-active & {
            color: $white;
          }
        }
      }
    }
  }

  .l-sidebar & {
    &__item {
      @include media-breakpoint-up(xxl) {
        width: 89%;
      }
    }
  }
}