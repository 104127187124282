@use "sass:math";

.o-hero-slider {
  @include media-breakpoint-up(sm) {
    margin-top: 3rem;
  }

  @include media-breakpoint-up($header-breakpoint) {
    margin-top: 5rem;
  }

  &__container {
    position: relative;
    padding: 0;
  }

  &__slider {
    overflow: hidden;
    // margin: 0 -4rem -4rem;
    margin:0 auto;
    width: calc(100% - 140px);

    @include media-breakpoint-down(sm) {
     width: auto;
     margin: 0 -4rem -4rem;
    }

    &-inner {
    }
  }

  &__item {
    padding: 0 4rem 4rem;

    .item {
      &__wrapper {
        position: relative;
        max-height: calc(100vh - #{$header-height-mobile});

        @include media-breakpoint-up(sm) {
          max-height: calc(100vh - #{$header-height-mobile} - 3rem);
        }

        @include media-admin-bar() {
          max-height: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
        }

        @include media-breakpoint-up($header-breakpoint) {
          max-height: calc(100vh - #{$header-height} - 5rem);

          @include media-admin-bar() {
            max-height: calc(100vh - #{$header-height} - 5rem - 32px);
          }
        }

        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 0;
          padding-top: 166.67%;

          @include media-breakpoint-up(xs) {
            padding-top: 150%;
          }

          @include media-breakpoint-up(sm) {
            padding-top: 100%;
          }
        }
      }

      &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;

        body.is-high-contrast-active & {
          background-color: $black;
        }

        @include media-breakpoint-down(xs) {
          overflow: hidden;
        }
      }

      &__content {
        z-index: 10;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
      }

      &__title {
        position: relative;
        width: 100%;
        padding: 0 3rem 1rem;
        margin: 0 0 8rem;
        font-size: 40px;

        @include media-breakpoint-up(sm) {
          padding: 0;
          margin: 0;
        }

        @include media-breakpoint-up(md) {
          opacity: 0;
          font-size: 55px;
          transform: translateY(-10%);
        }

        @include media-breakpoint-up(lg) {
          font-size: 70px;
        }

        @include media-breakpoint-up(xl) {
          max-width: 64%;
          font-size: 85px;
        }

        @include media-breakpoint-up(xxl) {
          max-width: 69%;
          font-size: 100px;
        }

        body.font-scale-mode--1 &,
        body.font-scale-mode--2 & {
          @include media-breakpoint-up(md) {
            font-size: 55px * 1.1;
          }

          @include media-breakpoint-up(lg) {
            font-size: 70px * 1.1;
          }

          @include media-breakpoint-up(xl) {
            font-size: 85px * 1.1;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 100px * 1.1;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 8rem;
          height: 1rem;
          background-color: $black;

          body.is-high-contrast-active & {
            background-color: $white;
          }

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
      }

      &__link {
        display: block;
        color: $black;
        width: auto;
        padding: 1rem;
        margin: 0 2rem 3rem;
        font-family: $font-family-headings;
        font-size: $font-size-sm;
        text-transform: uppercase;

        body.is-high-contrast-active & {
          color: $highContrastAccent;
        }

        @include media-breakpoint-up(sm) {
          position: absolute;
          bottom: 0;
          right: 0;
          margin: 0;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
        }

        body.font-scale-mode--1 & {
          font-size: $font-size-sm * $font-scale-1-multiplier;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }
        }

        body.font-scale-mode--2 & {
          font-size: $font-size-sm * $font-scale-2-multiplier;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base * $font-scale-2-multiplier;
          }
        }

        &-icon {
          color: inherit;
          transition: transform $transition-base;
          transform: rotate(-90deg) translate(2px);
        }

        &:hover {
          .item__link {
            &-icon {
              transform: rotate(0);
            }
          }
        }
      }

      &__bg {
        z-index: 2;
        position: relative;
      }

      &__img {
      }

      &__decor {
        display: none;

        &--1 {
          order: 2;
        }

        &--2 {
          order: 4;
        }

        &--3 {
          order: 6;
        }

        &--4 {
          order: 8;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &.is-animating {
        .item {
          &__title {
            animation-name: heroSliderTitle;
            animation-timing-function: ease-in-out;
            animation-duration: 2s;
            animation-iteration-count: 1;

            @keyframes heroSliderTitle {
              from {
                opacity: 0;
                transform: translateY(-10%);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
        }
      }

      &.is-animated-title {
        .item {
          &__title {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &--quarter-circle {
      .item {
        &__title {
          @include media-breakpoint-up(sm) {
            width: 50%;
            padding-right: $grid-gutter-width;
          }

          @include media-breakpoint-up($grid-breakpoint-change) {
            padding-right: $grid-gutter-width-desktop;
          }
        }

        &__bg {
          height: 100%;
        }

        &__img {
          z-index: 2;
          position: absolute;
          top: 3rem;
          right: 3rem;
          width: calc(100% - 9rem);
          max-width: calc(60vh - #{$header-height-mobile} - 3rem);
          filter: drop-shadow(10px 10px 10px rgba($black, 30%));

          body.is-high-contrast-active & {
            filter: none;
          }

          @include media-breakpoint-up(sm) {
            top: 0;
            right: 0;
            width: 50%;
            max-width: calc(100vh - #{$header-height-mobile} - 3rem);
            max-height: 100%;

            @include media-admin-bar() {
              max-width: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
            }
          }

          @include media-breakpoint-up($header-breakpoint) {
            max-width: calc(100vh - #{$header-height} - 5rem);

            @include media-admin-bar() {
              max-width: calc(100vh - #{$header-height} - 5rem - 32px);
            }
          }


          &-inner {
            mask-image: url('../../../../../assets/private/img/shapes/shape-message.svg');
            mask-size: 100%;
          }
        }

        &__decor {
          z-index: 1;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;

          @include media-breakpoint-up(sm) {
            overflow: hidden;
            width: calc(100vh - #{$header-height-mobile} - 3rem);
            height: calc(100vh - #{$header-height-mobile} - 3rem);
            max-width: map-get($container-max-widths, sm);
            max-height: map-get($container-max-widths, sm);

            @include media-admin-bar() {
              width: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
              height: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
            }
          }

          @include media-breakpoint-up($header-breakpoint) {
            width: calc(100vh - #{$header-height} - 5rem);
            height: calc(100vh - #{$header-height} - 5rem);

            @include media-admin-bar() {
              width: calc(100vh - #{$header-height} - 5rem - 32px);
              height: calc(100vh - #{$header-height} - 5rem - 32px);
            }
          }

          @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, md);
            max-height: map-get($container-max-widths, md);
          }

          @include media-breakpoint-up(lg) {
            max-width: map-get($container-max-widths, lg);
            max-height: map-get($container-max-widths, lg);
          }

          @include media-breakpoint-up(xl) {
            max-width: map-get($container-max-widths, xl);
            max-height: map-get($container-max-widths, xl);
          }

          @include media-breakpoint-up(xxl) {
            max-width: map-get($container-max-widths, xxl);
            max-height: map-get($container-max-widths, xxl);
          }

          &:before {
            content: '';
            display: block;
            height: 0;
            padding-top: 100%;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 250%;
            height: 250%;
            border: solid 150px $primary;
            border-radius: 50%;
            transform: translate(-55%, 50%);

            body.is-high-contrast-active & {
              border-color: $grey-600;
            }

            @include media-breakpoint-up(xs) {
              border-width: 180px;
            }

            @include media-breakpoint-up(sm) {
              width: 200%;
              height: 200%;
              border-width: calc((100vh - #{$header-height-mobile} - 3rem) * .42);
              transform: translate(-50%, 50%);

              @include media-admin-bar() {
                border-width: calc((100vh - #{$header-height-mobile} - 3rem - 32px) * .42);
              }
            }

            @include media-breakpoint-up($header-breakpoint) {
              border-width: calc((100vh - #{$header-height} - 5rem) * .42);

              @include media-admin-bar() {
                border-width: calc((100vh - #{$header-height} - 5rem - 32px) * .42);
              }
            }
          }

          &--1 {
            display: block;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &.is-animating {
          .item {
            &__img {
              animation-name: heroSliderQuarterCircle;
              animation-timing-function: ease-in-out;
              animation-duration: 8s;
              animation-iteration-count: 1;

              @keyframes heroSliderQuarterCircle {
                from {
                  transform: translate(0, 0);
                }
                to {
                  transform: translate(-10%, 5%);
                }
              }
            }
          }

        }

        &.is-animated-decor {
          .item {
            &__img {
              transform: translate(-10%, 5%);
            }
          }
        }
      }
    }

    &--chessboard {
      .item {
        &__link {
          @include media-breakpoint-up(sm) {
            right: auto;
            left: 0;
          }
        }

        &__bg {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -5.5vw;

          @include media-breakpoint-up(xs) {
            margin: 0;
          }

          @include media-breakpoint-up(sm) {
            width: calc(100vh - #{$header-height-mobile} - 3rem);
            max-width: 100%;
            margin-left: auto;

            @include media-admin-bar() {
              width: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
            }
          }

          @include media-breakpoint-up($header-breakpoint) {
            width: calc(100vh - #{$header-height} - 5rem);

            @include media-admin-bar() {
              width: calc(100vh - #{$header-height} - 5rem - 32px);
            }
          }
        }

        &__img,
        &__decor {
          width: 50%;

          @include media-breakpoint-up(sm) {
            width: calc((100vh - #{$header-height-mobile} - 3rem) / 3);
            height: calc((100vh - #{$header-height-mobile} - 3rem) / 3);
            max-width: map-get($container-max-widths, sm) / 3;
            max-height: map-get($container-max-widths, sm) / 3;

            @include media-admin-bar() {
              width: calc((100vh - #{$header-height-mobile} - 3rem - 32px) / 3);
              height: calc((100vh - #{$header-height-mobile} - 3rem - 32px) / 3);
            }
          }

          @include media-breakpoint-up($header-breakpoint) {
            width: calc((100vh - #{$header-height} - 5rem) / 3);
            height: calc((100vh - #{$header-height} - 5rem) / 3);

            @include media-admin-bar() {
              width: calc((100vh - #{$header-height} - 5rem - 32px) / 3);
              height: calc((100vh - #{$header-height} - 5rem - 32px) / 3);
            }
          }

          @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, md) / 3;
            max-height: map-get($container-max-widths, md) / 3;
          }

          @include media-breakpoint-up(lg) {
            max-width: map-get($container-max-widths, lg) / 3;
            max-height: map-get($container-max-widths, lg) / 3;
          }

          @include media-breakpoint-up(xl) {
            max-width: map-get($container-max-widths, xl) / 3;
            max-height: map-get($container-max-widths, xl) / 3;
          }

          @include media-breakpoint-up(xxl) {
            max-width: map-get($container-max-widths, xxl) / 3;
            max-height: map-get($container-max-widths, xxl) / 3;
          }
        }

        &__img {
          margin-left: 50%;
          box-shadow: 10px 10px 10px rgba($black, 30%);


          body.is-high-contrast-active & {
            box-shadow: none;
          }

          @include media-breakpoint-up(sm) {
            order: 9;
            margin: 0;
          }
        }

        &__decor {
          display: block;
          background-color: $primary;

          body.is-high-contrast-active & {
            background-color: $grey-600;
          }

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-top: 100%;

            @include media-breakpoint-up(sm) {
              display: none;
            }
          }

          &--1 {
            margin-right: 50%;

            @include media-breakpoint-up(sm) {
              margin-right: 33.33%;
            }
          }

          &--2 {
            margin-left: 50%;

            @include media-breakpoint-up(sm) {
              margin: 0;
            }
          }

          &--3 {
            display: none;

            @include media-breakpoint-up(sm) {
              display: block;
              margin: 0 33.33%;
            }
          }

          &--4 {
            display: none;

            @include media-breakpoint-up(sm) {
              display: block;
              margin-right: 33.33%;
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        &.is-animating {
          .item {
            &__img {
              animation-name: heroSliderChessboard;
              animation-timing-function: ease-in-out;
              animation-duration: 8s;
              animation-iteration-count: 1;

              @keyframes heroSliderChessboard {
                from {
                  transform: translate(0, 0);
                }
                to {
                  transform: translate(-75%, -75%);
                }
              }
            }
          }

        }

        &.is-animated-decor {
          .item {
            &__img {
              transform: translate(-75%, -75%);
            }
          }
        }
      }
    }

    &--fast-forward {
      .item {
        &__inner {
          @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: flex-end;
          }
        }

        &__title {
          width: 60%;
          padding-right: 0;
          margin-right: auto;
        }

        &__bg {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          height: 100%;
          max-width: 100%;
        }

        &__img {
          z-index: 1;
          position: absolute;
          top: 3rem;
          right: 3rem;
          left: 10rem;
          filter: drop-shadow(10px 10px 10px rgba($black, 30%));

          body.is-high-contrast-active & {
            filter: none;
          }

          @include media-breakpoint-up(sm) {
            right: auto;
            left: 44%;
            width: 36%;
            transform: translate(-50%);
          }

          &-inner {
            mask-image: url('../../../../../assets/private/img/shapes/shape-bitten-rectangle.svg');
            mask-size: 100%;
          }
        }

        &__decor {
          width: 100%;
          mask-image: url('../../../../../assets/private/img/shapes/shape-fast-forward.svg');
          mask-size: 100%;
          background-color: $primary;

          body.is-high-contrast-active & {
            background-color: $grey-600;
          }

          @include media-breakpoint-up(sm) {
            width: calc(102.272727vh - #{$header-height-mobile} - 3rem);
            max-width: 100%;
            margin-left: auto;

            @include media-admin-bar() {
              width: calc(102.272727vh - #{$header-height-mobile} - 3rem - 32px);
            }
          }

          @include media-breakpoint-up($header-breakpoint) {
            width: calc(102.272727vh - #{$header-height} - 5rem);

            @include media-admin-bar() {
              width: calc(102.272727vh - #{$header-height} - 5rem - 32px);
            }
          }

          &:before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 97.7777778%;
          }

          &--1 {
            display: block;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &.is-animating {
          .item {
            &__img {
              animation-name: heroSliderFastForward;
              animation-timing-function: ease-in-out;
              animation-duration: 8s;
              animation-iteration-count: 1;

              @keyframes heroSliderFastForward {
                from {
                  transform: translate(-50%, 0);
                }
                to {
                  transform: translate(-45%, 25%);
                }
              }
            }
          }

        }

        &.is-animated-decor {
          .item {
            &__img {
              transform: translate(-45%, 25%);
            }
          }
        }
      }
    }

    &--fuji {
      .item {
        &__link {
          color: $white;
        }

        &__bg {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          margin: 0 -8rem;

          @include media-breakpoint-up(sm) {
            width: calc(100vh - #{$header-height-mobile} - 3rem);
            max-width: 100%;
            margin: 0 0 0 auto;

            @include media-admin-bar() {
              width: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
            }
          }

          @include media-breakpoint-up($header-breakpoint) {
            width: calc(100vh - #{$header-height} - 5rem);

            @include media-admin-bar() {
              width: calc(100vh - #{$header-height} - 5rem - 32px);
            }
          }
        }

        &__img {
          order: 3;
          width: 100%;
          filter: drop-shadow(10px 10px 10px rgba($black, 30%));

          body.is-high-contrast-active & {
            filter: none;
          }

          &-inner {
            mask-image: url('../../../../../assets/private/img/shapes/shape-triangle.svg');
            mask-size: 100%;
          }
        }

        &__decor {
          background-color: $primary;
          width: 48%;
          border-radius: 50%;

          body.is-high-contrast-active & {
            background-color: $grey-600;
          }

          @include media-breakpoint-up(sm) {
            //width: calc((100vh - 50px - 3rem ) / 2);
            width: 50%;
            max-width: map-get($container-max-widths, sm) * 0.5;
          }

          @include media-breakpoint-up($header-breakpoint) {
            //width: calc((100vh - 50px - 5rem - 32px) / 2);
          }

          @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, md) / 2;
          }

          @include media-breakpoint-up(lg) {
            max-width: map-get($container-max-widths, lg) / 2;
          }

          @include media-breakpoint-up(xl) {
            max-width: map-get($container-max-widths, xl) / 2;
          }

          @include media-breakpoint-up(xxl) {
            max-width: map-get($container-max-widths, xxl) / 2;
          }

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-top: 100%;
          }

          &--1 {
            display: block;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &.is-animating {
          .item {
            &__decor {
              animation-name: heroSliderFuji;
              animation-timing-function: ease-in-out;
              animation-duration: 8s;
              animation-iteration-count: 1;

              @keyframes heroSliderFuji {
                from {
                  transform: translate(0, 0);
                }
                to {
                  transform: translate(45%, 45%);
                }
              }
            }
          }
        }

        &.is-animated-decor {
          .item {
            &__decor {
              transform: translate(45%, 45%);
            }
          }
        }
      }
    }
  }

  &__prev,
  &__next {
    z-index: 10;
    position: absolute;
    display: none;
    top: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    width: 70px;
    background-color: rgba($grey-200, 0.3);
    transition: opacity $transition-base, background-color $transition-base;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 10px;
      mask-image: url('../../../../../assets/private/img/shapes/shape-triangle.svg');
      mask-size: cover;
      background-color: $black;
    }

    &:hover {
      background-color: rgba($primary, 0.3);
    }
  }

  &:hover &__prev,
  &:hover &__next {
    opacity: 1;
  }

  &__prev {
    // left: -70px;
    left: -70px;
    &:before {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }

  &__next {
    right: -70px;
    // left: -70px;
    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}