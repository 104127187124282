.m-links {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  border-bottom: solid 1px $black;
  line-height: 0;

  body.is-high-contrast-active & {
    border-bottom-color: $white;
  }

  &__item {
    &--facebook .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-facebook.svg');
    }

    &--instagram .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-instagram.svg');
    }

    &--twitter .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-twitter.svg');
    }

    &--linkedin .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-linkedin.svg');
    }

    &--youtube .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-youtube.svg');
    }

    &--mail .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-mail.svg');
    }

    &--flickr .m-links__link::before {
      mask-image: url('../../../../../assets/private/img/icons/ico-flickr.svg');
    }

    &--usos,
    &--moodle {
      .m-links__link:before {
        display: none;
      }
    }
  }

  &__link {
    display: block;
    padding: 1.25rem 1.5rem;
    color: $black;
    line-height: 1;
    font-size: $font-size-sm;
    font-family: $font-family-headings;
    transition: color $transition-base;

    body.is-high-contrast-active & {
      color: $white
    }

    body.font-scale-mode--1 & {
      font-size: $font-size-sm * $font-scale-1-multiplier;
    }

    body.font-scale-mode--2 & {
      font-size: $font-size-sm * $font-scale-2-multiplier;
    }

    &:before {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      background-color: currentColor;
      mask-repeat: no-repeat;
      mask-position: center;
      transition: background-color $transition-base;
    }

    &:hover {
      color: $primary;
    }
  }
}