.a-font-scale {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 9px 1rem;
  color: inherit;
  &:hover {
    color: #404040;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: 15px;
    height: 1px;
    background-color: #000000;
    transition: left $transition-base, width $transition-base;

    body.is-high-contrast-active & {
      background-color: #ffffff;
    }
  }

  &__letter {
    font-family: $font-family-headings;
    font-weight: $font-weight-medium;

    &--1 {
      font-size: 18px;
      line-height: 18px;
    }

    &--2 {
      font-size: 20px;
      line-height: 20px;
    }

    &--3 {
      font-size: 22px;
      line-height: 22px;
    }
  }

  body.font-scale-mode--1 & {
    &:after {
      left: calc(1rem + 15px);
      width: 16px;
    }
  }

  body.font-scale-mode--2 & {
    &:after {
      left: calc(1rem + 31px);
      width: 17px;
    }
  }
}
