// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base:            $font-family-sans-serif !default;
$font-family-headings:        "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// stylelint-enable value-keyword-case

$font-size-base:              18px !default; // Assumes the browser default, typically `16px`
$font-size-lg:                20px !default;
$font-size-sm:                16px !default;
$line-height-base:            24px !default;

$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-bold:            700 !default;

$h1-font-size:                36px !default;
$h2-font-size:                24px !default;
$h3-font-size:                20px !default;
$h4-font-size:                16px !default;
$h5-font-size:                $h4-font-size !default;
$h6-font-size:                $h5-font-size !default;

$headings-size-breakpoint:    lg;

$h1-font-size-mobile:         32px;
$h2-font-size-mobile:         20px;
$h3-font-size-mobile:         16px;
$h4-font-size-mobile:         $h3-font-size-mobile !default;
$h5-font-size-mobile:         $h4-font-size-mobile !default;
$h6-font-size-mobile:         $h5-font-size-mobile !default;

$headings-font-family:        $font-family-headings !default;
$headings-font-weight:        $font-weight-medium !default;
$headings-line-height:        1.25 !default;
$headings-color:              null !default;
$headings-margin-bottom:      1em * $headings-line-height !default;

// Links
//
// Style anchor elements.

$link-color:                              $primary !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($primary, 15%) !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:                 $line-height-base !default;
$paragraph-margin-bottom-breakpoint:      lg !default;
$paragraph-margin-bottom-mobile:          $paragraph-margin-bottom !default;
