.cookie-notice {
  color: #fff;
  font-family: inherit;
  background: #000;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0px;
  visibility: hidden;
  z-index: 1000000;
  box-sizing: border-box;
  &__container {
    display: flex;
    align-items: center;
    padding-inline: 70px;
    @include media-breakpoint-down(md) {
      padding-inline: 50px;
      flex-direction: column-reverse;
    }
  }
  &__text {
    padding-right: 120px;
    font-size: 16px;
    @include media-breakpoint-down(md) {
      padding-right: 0px;
      font-size: 14px;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  &__btn {
    background-image: url("../../../../../assets/private/img/close.svg");
    color: #fff;
    background-color: #000;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }
}
