.m-table {
  &__container {
  }

  &__table {
    width: 100%;

    thead {
      th {
        padding-right: 1rem;
        padding-bottom: 2rem;
        font-family: $font-family-headings;
        font-weight: $font-weight-medium;

        @include media-breakpoint-up(lg) {
          padding-right: 2.5rem;
          padding-bottom: 3rem;
        }
      }
    }

    tbody {
      td {
        padding-right: 1rem;

        @include media-breakpoint-up(lg) {
          padding-right: 2.5rem;
        }
      }
    }
  }
}