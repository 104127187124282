.l-not-found {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  align-items: center;

  body.admin-bar & {
    @media (min-width: 783px) {
      min-height: calc(100vh - #{$header-height} - 32px);
    }
  }

  &__top {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 6rem;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  .content {
    &__text-wrapper {
      display: flex;
      align-items: flex-end;
    }

    &__text {
      font-size: 96px;
      font-weight: $font-weight-bold;
      color: $grey-200;
      line-height: 1;
      margin-bottom: 0;

      @include media-breakpoint-up(sm) {
        font-size: 150px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 288px;
      }

      body.is-high-contrast-active & {
        color: $white;
      }

      body.font-scale-mode--1 & {
        font-size: 96px * $font-scale-1-multiplier;

        @include media-breakpoint-up(sm) {
          font-size: 150px * $font-scale-1-multiplier;
        }

        @include media-breakpoint-up(lg) {
          font-size: 288px * $font-scale-1-multiplier;
        }
      }

      body.font-scale-mode--2 & {
        font-size: 96px * $font-scale-2-multiplier;

        @include media-breakpoint-up(sm) {
          font-size: 150px * $font-scale-2-multiplier;
        }

        @include media-breakpoint-up(lg) {
          font-size: 288px * $font-scale-2-multiplier;
        }
      }
    }
  }

  &__bottom {
    margin-top: 4rem;
    border-top: 1px solid $grey-300;
    padding-top: 3rem;

    body.is-high-contrast-active & {
      border-top-color: $white;
    }
  }

  &__heading {
    text-align: center;
    color: $grey-400;

    body.is-high-contrast-active & {
      color: $white
    }
  }
}