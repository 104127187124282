body.is-high-contrast-active {
  background-color: $black;
  color: $white;

  a {
    color: $white;

    &:hover {
      color: #404040;
    }
  }
}
