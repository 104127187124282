.o-events-calendar {
  max-width: 781px;

  &__header {

  }

  &__title {

  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 4rem 0 0;
  }
}

.qs {
  &-datepicker-container {
    width: 100%;
  }

  &-datepicker {
    width: 100%;
    font-family: $font-family-headings;
  }

  &-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 6rem;

    .qs {
      &-month-year {
        order: 1;
        flex: 1 1 auto;
        text-transform: uppercase;
        font-size: $h2-font-size-mobile;

        @include media-breakpoint-up($headings-size-breakpoint) {
          font-size: $h2-font-size;
        }

        body.font-scale-mode--1 & {
          font-size: $h2-font-size-mobile * $font-scale-1-multiplier;
        }

        body.font-scale-mode--2 & {
          font-size: $h2-font-size-mobile * $font-scale-2-multiplier;
        }

        .qs {
          &-month {
            margin-right: 1rem;
          }

          &-year {

          }
        }
      }

      &-arrow {
        position: relative;
        display: inline-block;
        overflow: hidden;
        flex: 0 0 auto;
        width: 3rem;
        height: 3rem;
        color: $black;
        cursor: pointer;

        body.is-high-contrast-active & {
          color: $white;
        }

        &:hover {
          color: $primary;
        }

        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          inset: 0;
          background-color: currentColor;
          mask-image: url('../../../../../assets/private/img/triangle-arrow.svg');
          mask-repeat: no-repeat;
          mask-position: center;
          transition: color $transition-base;
        }

        &.qs-left {
          order: 2;
          transform: rotate(90deg);
        }

        &.qs-right {
          order: 3;
        }
      }
    }
  }

  &-squares {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$grid-gutter-width;

    @include media-breakpoint-up($grid-breakpoint-change) {
      margin-right: -$grid-gutter-width-desktop;
    }

    .qs {
      &-square {
        width: calc(100% / 7 - #{$grid-gutter-width});
        margin-right: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
        font-size: $h2-font-size-mobile;

        @include media-breakpoint-up($grid-breakpoint-change) {
          width: calc(100% / 7 - #{$grid-gutter-width-desktop});
          margin-right: $grid-gutter-width-desktop;
          margin-bottom: $grid-gutter-width-desktop;
        }

        @include media-breakpoint-up($headings-size-breakpoint) {
          font-size: $h2-font-size;
        }

        body.font-scale-mode--1 & {
          font-size: $h2-font-size-mobile * $font-scale-1-multiplier;
          @include media-breakpoint-up($headings-size-breakpoint) {
            font-size: $h2-font-size * $font-scale-1-multiplier;
          }
        }

        body.font-scale-mode--2 & {
          font-size: $h2-font-size-mobile * $font-scale-2-multiplier;
          @include media-breakpoint-up($headings-size-breakpoint) {
            font-size: $h2-font-size * $font-scale-2-multiplier;
          }
        }

        &.qs-day {
          text-transform: uppercase;
          margin-bottom: 5rem;
          font-size: $font-size-sm;

          body.font-scale-mode--1 & {
            font-size: $font-size-sm * $font-scale-1-multiplier;
          }

          body.font-scale-mode--2 & {
            font-size: $font-size-sm * $font-scale-2-multiplier;
          }
        }

        &.qs-outside-current-month {

        }

        &.qs-num {
          padding: 1rem 0;
          border: solid 1px $black;
          background-color: $white;
          text-align: center;
          cursor: pointer;
          transition: border $transition-base, background-color $transition-base, color $transition-base;

          body.is-high-contrast-active & {
            border-color: $white;
            background-color: $black;
          }

          &.qs-event {
            border-color: $primary;

            body.is-high-contrast-active & {
              border-color: $highContrastAccent;
            }
          }

          &:hover,
          &.qs-active {
            border-color: $primary;
            background-color: $primary;
            color: $white;

            body.is-high-contrast-active & {
              border-color: $highContrastAccent;
              color: $highContrastAccent;
            }
          }
        }
      }
    }
  }

  &-overlay {
    display: none;
  }
}