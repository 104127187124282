.o-blocks-slider {
  &__header {
    &-container {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    flex: 1 1 auto;
    margin: 0 auto 0 0;
    text-transform: uppercase;
  }

  &__prev,
  &__next {
    flex: 0 0 auto;
    padding: 1.25rem;
    line-height: 0;
    color: inherit;

    &:hover {
      color: $primary;
    }

    &-icon {
      color: currentColor;
    }
  }

  &__prev {
    &-icon {
      transform: rotate(90deg);
    }
  }

  &__main {
    padding: 2rem 0;

    @include media-breakpoint-up(sm) {
      padding: 3rem 0;
    }

    @include media-breakpoint-up(md) {
      padding: 4.5rem 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 6rem 0;
    }

    &-container {
    }
  }

  &__slider {
    &-inner {
    }
  }

  &__item {
    height: auto;

    .item {
      &__inner {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        background-color: $white;
        color: $black;

        body.is-high-contrast-active & {
          background-color: $black;
          color: $white;
        }

        &:hover {
          color: $black;
        }
      }

      &__header {
        flex: 0 0 auto;
        position: relative;
        border-bottom: solid 1px $grey-400;

        body.is-high-contrast-active & {
          border-bottom-color: $white;
        }

        &:before {
          content: '';
          display: block;
          height: 0;
          padding-top: 90%;
        }

        &:after {
          content: '';
          z-index: 2;
          position: absolute;
          inset: 0;
          opacity: 0;
          background-color: rgba($primary, 0.4);
          transition: opacity $transition-base;

          // body.is-high-contrast-active & {
          //   display: none;
          // }
        }
      }

      &__icon {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 40vw;
        transform: translate(-50%, -50%);
        color: currentColor;

        @include media-breakpoint-up(sm) {
          font-size: 100px;
        }
      }

      &__image {
        z-index: 1;
        position: absolute;
        inset: 0;
      }

      &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-title {
          width: 100%;
          text-transform: uppercase;
        }

        &-description {
          width: 100%;
          margin-bottom: 1.25rem;
        }

        &-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          margin-top: auto;
        }

        &-date {
          margin-right: auto;
        }

        &-icon {
          transform: rotate(-90deg);
          transition: transform $transition-base;
          color: currentColor;
        }
      }
    }

    &:hover .item {
      &__header {
        &:after {
          opacity: 1;
        }
      }

      &__icon {
        color: $primary;

        body.is-high-contrast-active & {
          color: $white;
        }
      }

      &__content {
        &-icon {
          color: $primary;
          transform: rotate(0);

          body.is-high-contrast-active & {
            color: $white
          }
        }
      }
    }

    &--has-date {
      .item {
        &__content {
          &-description {
            margin-bottom: 10rem;
          }
        }
      }
    }

    &--has-icon {
      .item {
        &__header {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &--primary {
    .o-blocks-slider {
      &__header {
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 2rem;
        }
      }

      &__main {
        background-color: $primary;

        body.is-high-contrast-active & {
          background-color: $black;
        }
      }

      &__item {
        .item {
          &__inner {
            body.is-high-contrast-active & {
              border: solid 1px $white;
            }
          }
          &__content {
            padding: 1.25rem;
          }
        }
      }
    }
  }

  &--secondary {
    .o-blocks-slider {
      &__header {
        @include media-breakpoint-down(xs) {
          margin-bottom: 2rem;
        }
      }

      &__main {
        padding-bottom: 0;
      }

      &__item {
        .item {
          &__header {
            border-bottom: none;
            margin-bottom: 2rem;

            @include media-breakpoint-up(sm) {
              margin-bottom: 3rem;
            }

            @include media-breakpoint-up(md) {
              margin-bottom: 4rem;
            }

            @include media-breakpoint-up(lg) {
              margin-bottom: 5rem;
            }

            @include media-breakpoint-up(xl) {
              margin-bottom: 6rem;
            }

            &:before {
              padding-top: 68%;
            }
          }

          &__content {
            &-title {
              transition: color $transition-base;
            }

            &-footer {
              padding-bottom: 1rem;
              border-bottom: solid 1px $black;
              transition: border-bottom-color $transition-base;

              body.is-high-contrast-active & {
                border-bottom-color: $white;
              }
            }
          }
        }

        &:hover .item {
          &__content {
            &-title {
              color: $primary;

              body.is-high-contrast-active & {
                color: $white;
              }
            }

            &-footer {
              border-bottom-color: $primary;
            }
          }
        }
      }
    }
  }
}