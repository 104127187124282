.o-departments-list {
  &__container {

  }

  &__title {
    text-transform: uppercase;
  }

  &__items {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: -10rem;
    }
  }

  &__item {
    margin-bottom: 5rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 320px;
      margin-bottom: 6rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 8rem;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 10rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 10rem;
      margin-bottom: 12rem;
    }

    .item {
      &__inner {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        color: $black;

        body.is-high-contrast-active & {
          color: $white;
          &:hover > span {
            color: $primary;
          }
        }

        &:hover > span {
          color: $primary;
        }
      }

      &__icon {
        flex: 0 0 auto;
        font-size: 60px;
        margin-right: 1.25rem;
        color: currentColor;
      }

      &__text {
        margin-bottom: -0.25em;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        transition: color $transition-base;
      }
    }
  }

  .l-sidebar & {
    &__items {
      @include media-breakpoint-up(xl) {
        justify-content: flex-start;
      }
    }
  }
}