.l-sidebar {
  position: relative;

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity $transition-base;

    @include media-breakpoint-up($header-breakpoint) {
      display: none;
    }
  }

  &__container {
  }

  &__inner {
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }
  }

  &__aside {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    background-color: $white;
    transform: translateX(-100%);
    transition: transform $transition-base;

    @include media-breakpoint-down(lg) {
      max-width: 230px;
    }

    @include media-breakpoint-up($header-breakpoint) {
      transform: translateX(0);
      position: sticky;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }

    body.is-high-contrast-active & {
      background-color: $black;
    }

    &-inner {
      position: absolute;
      width: 100%;
      padding: 0 1rem;

      @include media-breakpoint-up($header-breakpoint) {
        padding-left: $grid-gutter-width-desktop/2;
        padding-right: $grid-gutter-width-desktop/2;
      }
    }

    &.is-sticky-over &-inner {
      top: 0;
    }

    &.is-sticky &-inner {
      position: sticky;
      top: $header-height-mobile;
      width: auto;

      @include media-admin-bar() {
        top: calc(#{$header-height-mobile} + 32px);
      }

      @include media-breakpoint-up($header-breakpoint) {
        top: $header-height;

        @include media-admin-bar() {
          top: calc(#{$header-height} + 32px);
        }
      }
    }

    &.is-sticky-under &-inner {
      bottom: 0;
    }
  }

  &__hamburger {
    position: absolute;
    top: 1rem;
    right: 0;
    padding: 2rem 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
    background-color: $white;
    line-height: 0;
    transform: translateX(100%);

    @include media-breakpoint-up($header-breakpoint) {
      display: none;
    }

    body.is-high-contrast-active & {
      background-color: $black;
      box-shadow: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2rem;
      background-color: $white;
      transform: translateX(-50%);

      body.is-high-contrast-active & {
        background-color: $black;
      }
    }
  }

  &__menu {
    overflow-y: auto;
    max-height: calc(100vh - #{$header-height-mobile});

    @include media-admin-bar() {
      max-height: calc(100vh - #{$header-height-mobile} - 32px);
    }

    @include media-breakpoint-up($header-breakpoint) {
      max-height: calc(100vh - #{$header-height});

      @include media-admin-bar() {
        max-height: calc(100vh - #{$header-height} - 32px);
      }
    }
  }

  .menu {
    &__list {
      list-style: none;
      padding: 0;
      margin: 0 0 0 -1rem;
    }

    &__item {
      margin-bottom: 1.5rem;
    }

    &__link {
      position: relative;
      display: block;
      padding: 1.25rem 1rem;
      font-size: $font-size-sm;
      font-family: $font-family-headings;
      color: $black;
      transition: color $transition-base;

      body.is-high-contrast-active & {
        color: $white;
      }

      body.font-scale-mode--1 & {
        font-size: $font-size-sm * $font-scale-1-multiplier;
      }

      body.font-scale-mode--2 & {
        font-size: $font-size-sm * $font-scale-2-multiplier;
      }

      &:after {
        content: "";
        position: absolute;
        right: 2rem;
        bottom: 0;
        left: 1rem;
        height: 1px;
        background-color: currentColor;
        transition: background-color $transition-base, right $transition-base;
      }

      &:hover {
        color: $primary;
      }

      &:hover,
      &--current {
        &:after {
          background-color: $primary;
          right: 0;

          body.is-high-contrast-active & {
            background-color: $white;
          }
        }
      }
    }

    &__content {
      .container {
        padding: 0;
      }
    }
  }

  &__main {
    .l-section:first-child {
      margin-top: 0;
    }

    .container {
      padding: 0;
    }
  }

  &.is-active {
    &:after {
      pointer-events: all;
      opacity: 1;
    }

    .l-sidebar {
      &__aside {
        transform: translateX(0);
      }
    }
  }
}
