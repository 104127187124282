$white: #FFFFFF;
$black: #000000;

$orange: #FF9800;
$highContrastAccent: #fcff3c;

$primary: $orange;

$error: #B6190A;
$warning: #C27E31;
$notice: #5A833A;

$grey-100: #EAEAEA;
$grey-200: #CECECE;
$grey-300: #D5D5D5;
$grey-400: #707070;
$grey-600: #404040;

$body-color: $black;
$body-bg: $white;
