.o-hero-image {
  @include media-breakpoint-up(sm) {
    margin-top: 3rem;
  }

  @include media-breakpoint-up($header-breakpoint) {
    margin-top: 5rem;
  }
// &--departments {
//   height:83rem;
// }
  &__container {
    position: relative;
    height: 100%;

    @include media-breakpoint-down(xs) {
      padding: 4rem 4rem 0;
    }
  }

  &__wrapper {
    position: relative;
    max-height: calc(100vh - #{$header-height-mobile});

    @include media-breakpoint-up(sm) {
      max-height: calc(100vh - #{$header-height-mobile} - 3rem);
    }

    @include media-admin-bar() {
      max-height: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
    }

    @include media-breakpoint-up($header-breakpoint) {
      max-height: calc(100vh - #{$header-height} - 5rem);

      @include media-admin-bar() {
        max-height: calc(100vh - #{$header-height} - 5rem - 32px);
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-top: 120%;

      @include media-breakpoint-up(sm) {
        padding-top: 100%;
      }
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__content {
    z-index: 2;
    position: relative;

    &-heading {
      margin-bottom: 13vh;
      &--departments{
        margin-bottom:  0;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 2rem;
        &--departments{
          margin-top:  0;
        }
      }

      @include media-breakpoint-up(xl) {
        margin-top: 4rem;
        &--departments{
          margin-top:  0;
        }
      }

      .container {
        padding: 0;
      }
    }

    &-title {
      position: relative;
      width: 100%;
      margin: 0;

      @include media-breakpoint-up(md) {
        max-width: 66%;
        font-size: 55px;
      }

      @media (min-aspect-ratio: 14/10) {
        @include media-breakpoint-up(md) {
          max-width: calc(100% - ((100vh - #{$header-height-mobile} - 3rem) * 66 / 100));

          @include media-admin-bar() {
            max-width: calc(100% - ((100vh - #{$header-height-mobile} - 3rem - 32px) * 66 / 100))
          }
        }

        @include media-breakpoint-up($header-breakpoint) {
          max-width: calc(100% - ((100vh - #{$header-height} - 5rem) * 66 / 100));

          @include media-admin-bar() {
            max-width: calc(100% - ((100vh - #{$header-height} - 5rem - 32px) * 66 / 100));
          }
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 70px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 85px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 100px;
      }

      body.font-scale-mode--1 & {
        @include media-breakpoint-up(md) {
          font-size: 55px * $font-scale-1-multiplier;
        }

        @include media-breakpoint-up(lg) {
          font-size: 70px * $font-scale-1-multiplier;
        }

        @include media-breakpoint-up(xl) {
          font-size: 85px * $font-scale-1-multiplier;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 100px * $font-scale-1-multiplier;
        }
      }

      body.font-scale-mode--2 & {
        @include media-breakpoint-up(md) {
          font-size: 55px * $font-scale-2-multiplier;
        }

        @include media-breakpoint-up(lg) {
          font-size: 70px * $font-scale-2-multiplier;
        }

        @include media-breakpoint-up(xl) {
          font-size: 85px * $font-scale-2-multiplier;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 100px * $font-scale-2-multiplier;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 8rem;
        height: 1rem;
        background-color: $black;

        body.is-high-contrast-active & {
          background-color: $white;
        }

        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: inherit;

    @include media-breakpoint-up(sm) {
      max-width: calc(100vh - #{$header-height-mobile} - 3rem);
    }

    @include media-admin-bar() {
      max-width: calc(100vh - #{$header-height-mobile} - 3rem - 32px);
    }

    @include media-breakpoint-up($header-breakpoint) {
      max-width: calc(100vh - #{$header-height} - 5rem);

      @include media-admin-bar() {
        max-width: calc(100vh - #{$header-height} - 5rem - 32px);
      }
    }
  }

  &__img {
    width: 90%;
    margin-left: auto;
    filter: drop-shadow(10px 10px 10px rgba($black, 30%));

    body.is-high-contrast-active & {
      filter: none;
    }

    @include media-breakpoint-up(sm) {
      width: 100%;
    }
    
    &-inner {
      mask-image: url('../../../../../assets/private/img/shapes/shape-stairs.svg');
      mask-size: 100%;
    }
    &-inner--departments{
      mask-image: url('../../../../../assets/private/img/shapes/shape-qarter-circle.svg');
      mask-size: 100%;
    }
  }
  &__logosDepartments {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
&__logoDepartments{
  @include media-breakpoint-down(xl) {
    width:150px;
  }
  @include media-breakpoint-down(lg) {
    width:120px
  }
  @include media-breakpoint-down(md) {
    width:100px
  }
  @include media-breakpoint-down(sm) {
    width:80px
  }
}
&__logoDepartments1 {
transform: translateY(-4rem);
padding-right:2rem;
}
&__logoDepartments2 {
  padding-right:2rem;
}
&__logoDepartments3 {
  transform: translateY(-6rem);
  padding-right:2rem;
}
&__logoDepartments4 {
  transform: translateY(-2rem);
}
}