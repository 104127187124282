.l-header-mobile {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height-mobile;

  @include media-admin-bar() {
    top: 32px;
  }

  @include media-breakpoint-up($header-breakpoint) {
    display: none;
  }

  &__top {
    height: 100%;
    background-color: $primary;

    body.is-high-contrast-active & {
      background-color: $black;
      border-bottom: solid 1px $white;
    }

    .top {
      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }

      &__logo {}
    }
  }

  &__main {
    pointer-events: none;
    z-index: 100;
    position: fixed;
    top: $header-height-mobile;
    right: 0;
    bottom: 0;
    left: 0;

    @include media-admin-bar() {
      top: calc(#{$header-height-mobile} + 32px)
    }

    .main {
      &__inner {
        pointer-events: all;
        z-index: 100;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 360px;
        height: 100%;
        padding: 0;
        background-color: $white;
        transform: translate(100%);
        transition: transform $transition-base;

        body.is-high-contrast-active & {
          background-color: $black;
        }
      }

      &__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem 4.5rem;

        &--high-contrast {
          &:hover {
            color: $primary;
          }
        }
      }

      &__nav {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;

        &-primary {
          padding: 0 4.5rem;
        }

        &-secondary {
          padding: 0 4.5rem;
          margin-top: 4rem;
        }
      }

      &__links {
        flex: 0 0 auto;
        padding: 2rem 4.5rem 2.5rem;
        border-top: solid 1px $black;
        margin: 0;

        body.is-high-contrast-active & {
          border-top-color: $white;
        }
      }
    }
  }

  &__backdrop {
    pointer-events: none;
    z-index: 90;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: rgba($black, 0.5);
    transition: opacity $transition-base;

    body.is-high-contrast-active & {
      background-color: rgba($white, 0.5);
    }
  }

  &.is-active {
    .main {
      &__inner {
        transform: translate(0);
      }
    }

    .l-header-mobile__backdrop {
      pointer-events: all;
      opacity: 1;
    }
  }
}